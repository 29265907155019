<template>
  <sheet-filters
    v-model="obFilterData"
    :model-name="modelClassName"
    :persistent="persistent"
    @apply="applyFilters"
    @reset="resetFilters"
  >
    <template #default="{ apply }">
      <v-row>
        <v-col cols="4" lg="2">
          <v-switch
            v-model="obFilterData.active"
            :label="$t('active')"
            :value="1"
            @change="apply"
          ></v-switch>
        </v-col>

        <v-col cols="12" lg="4">
          <search-field
            v-model="obFilterData.search"
            :hint="$t('company.filters.search.hint')"
            clearable
            persistent-hint
            @save="apply"
          />
        </v-col>
      </v-row>
      <!-- <loading-overlay :loading="loading" local /> -->
    </template>
  </sheet-filters>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import CategoriesSelect from "@/modules/categories/components/CategoriesSelect.vue";
import SearchField from "@/components/form/fields/Search.vue";
import PriceListSelect from "@/modules/pricelists/components/PriceListSelect.vue";
import SheetFilters from "@/components/form/SheetFilters.vue";
import CompaniesMixin from "@/modules/companies/mixins/CompaniesMixin";
import { AppModule } from "@/store/app";

@Component({
  components: { SheetFilters, PriceListSelect, SearchField, CategoriesSelect },
})
export default class CompanyFilters extends Mixins(CompaniesMixin) {
  @Prop(Boolean) readonly persistent!: boolean;

  obFilterData: Record<string, any> = {};

  onMounted() {
    this.obFilterData = this.$_.get(AppModule.filters, this.modelClassName, {});
  }

  applyFilters() {
    this.$emit("apply", this.obFilterData);
  }

  resetFilters() {
    this.$emit("input", {});
    this.$emit("reset");
    this.applyFilters();
  }
}
</script>
