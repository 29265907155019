<template>
  <v-autocomplete
    v-model="categoryId"
    :label="hideLabel ? undefined : $t('category')"
    v-bind="obData"
    @change="onSelect"
  >
    <template v-slot:item="data">
      <template v-if="!data.item.preview_image">
        <v-list-item-content v-text="data.item.name"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-avatar>
          <img :src="data.item.preview_image" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.name"></v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import { Vue, Component, VModel, Prop } from "vue-property-decorator";
import { AppModule } from "@/store/app";
import { defaults, get } from "lodash";

@Component
export default class CategoriesSelect extends Vue {
  @VModel({ type: Number, default: null }) categoryId!: number;

  @Prop(Boolean) readonly hideLabel!: boolean;
  @Prop(Boolean) readonly clearable!: boolean;

  get categories() {
    return AppModule.categories;
  }

  get obData() {
    return defaults(this.$attrs, {
      label: this.hideLabel ? undefined : this.$t("category"),
      items: this.categories.getModelList(),
      menuProps: { offsetY: true },
      hideDetails: this.sHideDetails,
      itemValue: "id",
      itemText: "name",
      outlined: true,
      dense: true,
      clearable: this.clearable,
    });
  }

  get sHideDetails() {
    return get(this.$attrs, "hideDetails", "auto");
  }

  onSelect(iValue: number) {
    this.$emit("input", iValue);
    this.$emit("change", iValue);
  }
}
</script>
