<template>
  <module-container
    :hide-new-button="!canCreate"
    :title="$t('companies')"
    route-name="companies"
    show-toolbar
  >
    <template #route>
      <router-view />
    </template>

    <template v-if="showFilters" #filters>
      <company-filters />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import CompanyFilters from "@/modules/companies/components/CompanyFilters.vue";
import type { RouteNamedMap } from "@/types/typed-router";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";

@Component({
  components: { CompanyFilters },
})
export default class CompaniesIndex extends Mixins(PaginateProviderMixin) {
  get canCreate() {
    const arRouteNameList: (keyof RouteNamedMap)[] = [
      "companies.settings",
      "companies.invoice_types",
      "companies.currencies",
      "companies.paymentmethods",
      "companies.currency_rates",
    ];

    return (
      (this.$ability.can("manage", "all") ||
        this.$ability.can("create", "Company")) &&
      !arRouteNameList.includes(this.$route.name as keyof RouteNamedMap)
    );
  }

  get showFilters() {
    return this.$route.name && this.$route.name === "companies.list";
  }
}
</script>
